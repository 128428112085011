import InfiniteScroll from "react-infinite-scroll-component";

import React from "react";

import { images } from "../../utils/constants/assets";


interface InfiniteScrollListProps<T> {
  items: T[];
  noMoreMessage: string;
  hasNext: boolean;
  fetchMoreData: () => void;
  renderItem: (item: T, index: number) => React.ReactNode;
}


const InfiniteScrollList = <T,>({ items, noMoreMessage, hasNext, fetchMoreData, renderItem }: InfiniteScrollListProps<T>) => {
  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasNext}
      loader={
        <span className="loading_icon">
          <img src={images.loading_icon} alt="loading..."/>
        </span>
      }
      endMessage={<p style={{ textAlign: "center" }}>
        <b>{ noMoreMessage }</b>
      </p>}
    >
      <div>{items.map(renderItem)}</div>
    </InfiniteScroll>
  )
}

export default InfiniteScrollList;
