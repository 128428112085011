import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getLocalStorage, removeLocalStorage } from "../../utils";
import { ROUTES_CONSTANTS } from "../../utils/constants/routesString";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import {
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import Bookings from "../Bookings/Bookings";
import { phoneValidation } from "../onboarding/ValidationSchema";
import Otp from "./Otp";
import SignUp from "./SignUp";
import SignUpForm from "./SignUpForm";

export default function SignIn() {
  const navigate = useNavigate();

  const [stepNo, setStepNo] = useState(1);

  console.log(navigate, "navigate");

  // const one = navigate("hhh");
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: { mobile_number: "" },
    resolver: yupResolver(phoneValidation),
    // mode: 'onChange',
  });

  const is_not_registered = getLocalStorage("is_registered_online_consumer1");
  const is_registered = getLocalStorage("is_registered_online_consumer");
  const handleNext = () => {
    handleSubmit((data) => {
      if (stepNo === 2 && is_registered) {
        const urlParams = new URLSearchParams(window.location.search);
        let uc = urlParams.get("stylist_unique_code");

        removeLocalStorage("is_registered");

        if (window.location.href.includes("notlogged")) {
          navigate(-1);
        } else {
          navigate(`/${getURLstylistCode()}/bookings`);
        }
        // setStepNo(1);
      } else {
        setStepNo(stepNo + 1);
      }

      if (stepNo === 3 && is_not_registered) {
        const urlParams = new URLSearchParams(window.location.search);
        let uc = urlParams.get("stylist_unique_code");

        removeLocalStorage("is_not_registered");

        if (window.location.href.includes("notlogged")) {
          navigate(-1);
        } else {
          navigate(`/${getURLstylistCode()}/bookings`);
        }
        // setStepNo(1);
      } else if (stepNo === 3) {
        navigate(`/${getURLstylistCode()}/bookings`);
      } else {
        setStepNo(stepNo + 1);
      }
    })();
  };
  const handlePrevious = () => {
    setStepNo(stepNo - 1);
  };

  return (
    <div>
      <div className="booking-wrapper">
        {stepNo === 1 && (
          <SignUp
            control={control}
            watch={watch}
            errors={errors}
            handleNext={handleNext}
            // handlePrev={handlePrev}
            isValid={isValid}
            setValue={setValue}
            getValues={getValues}
          />
        )}

        {stepNo === 2 && (
          <Otp
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            getValues={getValues}
            setValue={setValue}
            setStepNo={setStepNo}
          />
        )}

        {stepNo === 3 && (
          <SignUpForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            getValues={getValues}
            setValue={setValue}
            control={control}
            watch={watch}
            errors={errors}
            isValid={isValid}
          />
        )}
        {/* {stepNo === 3 && {one}} */}
      </div>
    </div>
  );
}
