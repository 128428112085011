import { create } from "zustand";



const useStore = create((set) => ({
  showNav: false,
  stylistUniqueCode: null,
  locationNotFound: false,
  bookingId: null,
  accessToken: null,
  stylistLocationData: null,
  location_id: null,
  timezone_id: null,
  selectedDate: null,
  rebookLocation: null,
  tz: null,
  bookingAPIData: null,
  currentTime: null,
  timing: [],
  selectedServices: [],
  selectedTime: null,
  reschedule: false,
  rescheduleBookingID: null,
  bookingDetail: null,
  stepNoFunc: "Check",
  mobileWorkingHours: [],
  locationWorkingHours: [],
  locationLatitude: null,
  locationLongitude: null,

  setStepNumber: (step) => {
    set((state) => ({ ...state, stepNoFunc: step }));
  },
  bookingDuration: { startTime: null, duration: null },

  setShowNav: (status) => {
    set((state) => ({ ...state, showNav: status }));
  },
  setLocationNotFound: (locationNotFound) => {
    set((state) => ({ ...state, locationNotFound }));
  },

  setRebookLocation: (data) => {
    set((state) => ({ ...state, rebookLocation: data }));
  },

  setBookingId: (id) => {
    set((state) => ({ ...state, bookingId: id }));
  },

  setReschedule: (status) => {
    set((state) => ({ ...state, reschedule: status }));
  },
  setRescheduleBookingID: (id) => {
    set((state) => ({ ...state, rescheduleBookingID: id }));
  },
  setStylistUniqueCode: (id) => {
    set((state) => ({ ...state, stylistUniqueCode: id }));
  },
  setStylistLocationData: (data) => {
    set((state) => ({ ...state, stylistLocationData: data }));
  },
  setLocationId: (id) => {
    set((state) => ({ ...state, location_id: id }));
  },

  setTimezoneId: (timezone) => {
    set((state) => ({ ...state, timezone_id: timezone }));
  },

  setSelectedDate: (date, tz, currentTime) => {
    set((state) => ({ ...state, selectedDate: date, tz, currentTime }));
  },
  setTiming: (timing) => {
    set((state) => ({ ...state, timing }));
  },
  setSelectedService: (services) => {
    set((state) => ({ ...state, selectedServices: services }));
  },
  setSelectedTime: (selectedTime) => {
    set((state) => ({ ...state, selectedTime }));
  },
  setBookingDuration: (startTime, duration) => {
    set((state) => ({ ...state, bookingDuration: { startTime, duration } }));
  },

  setBookingDetail: (data) => {
    set((state) => ({ ...state, bookingDetail: data }));
  },

  setBookingAPIData: (data) => {
    set((state) => ({
      ...state,
      bookingAPIData: data,
      bookingDetail: state.bookingDetail ? state.bookingDetail : data,
    }));
  },

  setMobileWorkingHours: (hours) => set({ mobileWorkingHours: hours }),
  setLocationWorkingHours: (hours) => set({ locationWorkingHours: hours }),
  setLocationLatitude: (coordinates) => set({ locationLatitude: coordinates }),
  setLocationLongitude: (coordinates) => set({ locationLongitude: coordinates }),
}));

export default useStore;
