import React, { useEffect, useState } from "react";
import InfiniteScrollList from "../../global/components/InfiniteScrollList";
import { Link } from "react-router-dom";
import { makeGetCall } from "../../effects/requests";
import { BOOKING_STATUS } from "../../types";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import endpoints from "../../utils/endpoints";
import { getURLstylistCode, convertToTimeZoneDate, convertToTimeZoneTime } from "../../utils/globalFunctions";
import useStore from "../../zustandstore";

interface Booking {
  id: string;
  booking_id: string;
  stylist_name: string;
  online_status: number;
  is_deleted: boolean;
  deposited_amount: number;
  start_dt: string;
  end_dt: string;
  timezone: string;
  services?: { name?: string; service__name?: string }[];
  products?: { name?: string; product__name?: string }[];
  address: string;
}

export default function Bookings() {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const setShowNav = useStore((state: any) => state.setShowNav);
  const [bookingType, setBookingType] = useState<"upcoming" | "past">(
    window.location.href.includes("rebook") ? "past" : "upcoming"
  );
  
  /**
   * @returns Stylist Unique Code from the url.
   */
  const fetchStylistUniqueCode = () => {
    if (typeof window === "undefined") return null;
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    return pathSegments.length > 0 ? pathSegments[0] : null;
  };
  
  const fetchBookings = async (url?: string) => {
    if (isLoading && !isInitialLoad) return;
    setIsLoading(true);

    try {
      const stylistCode = fetchStylistUniqueCode();
      const apiResponse = await makeGetCall({
        url: url || endpoints.customer_booking_list(stylistCode),
        params: url ? undefined : { status: bookingType },
      });
      
      const bookingList: Booking[] = apiResponse?.data?.results || [];
      setNextUrl(apiResponse?.data?.next);
      
      if (url) {
        setBookings(prev => [...prev, ...bookingList]);
      } else {
        setBookings(bookingList);
      }
    } catch (err) {
      console.error("Error fetching bookings:", err);
    } finally {
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  };

  const handleLoadMore = () => {
    if (nextUrl && !isLoading) {
      fetchBookings(nextUrl);
    }
  };

  const getBookingStatus = (booking: Booking) => {
    if (booking.online_status === 0) {
      return STRING_CONSTANTS.booking_pending;
    }
    if (booking.online_status === 1 && booking?.deposited_amount > 0) {
      return "Paid"
    }
    if (booking.online_status === 1) {
      return STRING_CONSTANTS.booking_confirmed;
    }
    // TODO: Implement the other statuses on other ticket.
    // if (booking.online_status === 2) {
    //   return STRING_CONSTANTS.booking_rejected;
    // }
    // if (booking.online_status === 3) {
    //   return STRING_CONSTANTS.booking_created;
    // }
    // if (booking.online_status === 4) {
    //   return STRING_CONSTANTS.booking_updated;
    // }
    if (booking.online_status === 5 || booking.is_deleted) {
      return STRING_CONSTANTS.booking_cancelled;
    }
    return STRING_CONSTANTS.booking_confirmed;
  };

  useEffect(() => {
    setBookings([]);
    setNextUrl(null);
    setIsInitialLoad(true);
    setIsLoading(true);
    fetchBookings();
  }, [bookingType]);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div className="booking-wrapper">
      <div className="sign_up_screen thankyou Bookings">
        <div className="upper-wrapper">
          <div className="back-logo">
            <div className="go-back my-profile">
              <div className="go-back" onClick={navOpen}>
                <img src={images.go_back} alt="Go Back" />
              </div>
            </div>
          </div>

          <div className="order-status-wrapper">
            <p>{STRING_CONSTANTS.select_booking_heading}</p>
            <div className="outer-history">
              <ul>
                {["upcoming", "past"].map((type) => (
                  <li
                    key={type}
                    className={bookingType === type ? "active" : ""}
                    onClick={() => setBookingType(type as "upcoming" | "past")}
                  >
                    {STRING_CONSTANTS[`booking_${type}`]}
                  </li>
                ))}
              </ul>
            </div>

            <InfiniteScrollList
              items={bookings}
              fetchMoreData={handleLoadMore}
              hasNext={Boolean(nextUrl)}
              noMoreMessage={isInitialLoad ? "Loading..." : STRING_CONSTANTS.booking_no_more}
              renderItem={(booking) => (
                <div key={booking.id} className="thankyou-inner">
                  <div className="thanks-top upcoming-top">
                    <div className="Salon-name-outer">
                      <div className="order-number">
                        <span>
                          {STRING_CONSTANTS.booking_no} {booking.booking_id}
                        </span>
                        <p>{booking.stylist_name}</p>
                      </div>

                      <div
                        className={`order-status confirmed ${getBookingStatus(booking).toLowerCase()}`}
                      >
                        {getBookingStatus(booking)}
                      </div>
                    </div>

                    <div className="date-time">
                      <div className="date">
                        <img src={images.date_icon} alt="Date" />
                        {convertToTimeZoneDate(
                          booking.start_dt,
                          booking.timezone,
                          "Do MMM, YYYY"
                        )}
                      </div>
                      <div className="time">
                        <img src={images.time_icon} alt="Time" />
                        {convertToTimeZoneTime(
                          booking.start_dt,
                          booking.timezone,
                          "hh:mm A"
                        )}
                        -{" "}
                        {convertToTimeZoneTime(
                          booking.end_dt,
                          booking.timezone,
                          "hh:mm A"
                        )}
                      </div>
                    </div>

                    {booking.services && booking.services.length > 0 && (
                      <div className="services-booked">
                        <b>List of Services: </b>
                        <span className="name">
                          {booking.services
                            .map(
                              (service) => service.name || service.service__name
                            )
                            .join(", ")}
                        </span>
                      </div>
                    )}

                    {booking.products && booking.products.length > 0 && (
                      <div className="product-booked services-booked">
                        <b>List of Products: </b>
                        <span className="name">
                          {booking.products
                            .map(
                              (product) => product.name || product.product__name
                            )
                            .join(", ")}
                        </span>
                      </div>
                    )}

                    <div className="address-map booking-address">
                      <div className="adrs">
                        <img src={images.address_icon} alt="Address" />
                        <span className="booking-address">{booking.address}</span>
                      </div>
                    </div>
                  </div>

                  <div className="thanks-bottom">
                    {bookingType === "upcoming" ? (
                      <>
                        <Link to={`/${getURLstylistCode()}/reschedule/?id=${booking.id}`}>
                          {STRING_CONSTANTS.booking_reschedule}
                        </Link>
                        <Link
                          to={`/${getURLstylistCode()}/booking_details/?id=${booking.id}`}
                          className="view-detail"
                        >
                          {STRING_CONSTANTS.booking_view_details}
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to={`/${getURLstylistCode()}/rebook/?id=${booking.id}`}>
                          {STRING_CONSTANTS.booking_rebook}
                        </Link>
                        <Link
                          to={`/${getURLstylistCode()}/booking_details/?id=${booking.id}`}
                          className="view-detail"
                        >
                          {STRING_CONSTANTS.booking_view_details}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}