import React, { useEffect, useState } from "react";
import { makeGetCall, makePatchCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import endpoints from "../../utils/endpoints";
import {
  convertLocal,
  getURLstylistCode,
  getURLwithstylistCode,
  convertToTimeZoneDate,
  convertToTimeZoneTime
} from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import { Link, useNavigate } from "react-router-dom";
import Cancellation from "./Cancellation";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { images } from "../../utils/constants/assets";
import { showToast } from "../../utils";
import moment from "moment";
import { currentDate } from "../../utils/dateUtils";
import { BOOKING_STATUS } from "../../types";

interface Bookingdetails {
  bookingDetail?: any;
  stylistUniqueCode?: any;
  setBookingId?: any;
  // setBookingDetail?: any;
  // getResponse?: any;
}
export default function Confirmed(props: Bookingdetails) {
  const {
    stylistUniqueCode,
    setBookingId,
    // getResponse,
    bookingDetail,
  } = props;

  const [showCancel, setShowCancel] = useState(false);
  const {
    mainstylistLocationData,
    setStylistUniqueCode,
    // bookingAPIData,
    // setBookingDetail,
    // bookingDetail,
  } = useStore((state: any) => state);

  //+++++++++++
  // async function getResponse() {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   let uc = urlParams.get("stylist_unique_code");
  //   setStylistUniqueCode(urlParams.get("stylist_unique_code"));

  //   let uc1 = urlParams.get("id");

  //   setBookingId(urlParams.get("id"));

  //   const params = {
  //     stylist_unique_code: uc,
  //   };

  //   try {
  //     const apiResponse = await makeGetCall({
  //       url: `${endpoints.booking}${uc1}/`,

  //       params,
  //     });

  //     setBookingDetail(apiResponse);
  //   } catch (err) {

  //   }
  // }

  // useEffect(() => {
  //   getResponse();
  // }, []);

  // useEffect(() => {
  //   if (bookingDetail === null) {

  //     setBookingDetail(bookingAPIData);
  //   }
  // }, []);

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        bookingDetail?.location[0] +
        "," +
        bookingDetail?.location[1],
      "_blank"
    );
  };
  const [showPopup, setShowPopup] = useState(false);

  const cancelpopup = () => {
    setShowPopup(!showPopup);
  };

  const [disableCancel, setDisableCancel] = useState(false);

  const handleConfirm = async () => {
    setDisableCancel(true);
    let bookingAPIPayload: any = {
      online_status: "2",
      is_deleted: "true",
    };

    const urlParams = new URLSearchParams(window.location.search);

    let uc1 = urlParams.get("id");

    setBookingId(urlParams.get("id"));

    const apiResponse = await makePatchCall({
      url: `${endpoints.booking}${uc1}/?${getURLwithstylistCode()}`,
      apiPayload: bookingAPIPayload,
    });

    if (apiResponse.status.code === 200) {
      setShowPopup(false);
      setShowCancel(true);
    } else {
      showToast(apiResponse.status.message, "error");
    }
  };

  const navigate = useNavigate();

  const [bookingType, setBookingType] = useState("upcoming");

  const [mainstylistLocationDataa, setMainstylistLocationDataa] =
    useState<any>();

  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      setMainstylistLocationDataa(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  const setShowNav = useStore((state: any) => state.setShowNav);
  const navOpen = () => {
    setShowNav(true);
  };

  const [showPopup1, setShowPopup1] = useState(false);

  const cancelpopup1 = () => {
    setShowPopup1(!showPopup1);
  };

  const [futureDate, setFutureDate] = useState(false);

  const selectedDate = moment.utc(bookingDetail?.start_dt).local();
  console.log(convertToTimeZoneTime(
    bookingDetail?.start_dt,
    bookingDetail?.timezone,
    "hh:mm A"
  ), "bookingDetail")

  useEffect(() => {
    if (selectedDate.isAfter(currentDate)) {
      setFutureDate(true);
    }
  }, []);

  let cancelTimings;
  if (bookingDetail?.modified_at) {
    cancelTimings =
      moment(bookingDetail?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingDetail?.start_dt);
  } else {
    cancelTimings =
      moment(bookingDetail?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingDetail?.start_dt);
  }

  return (
    <>
      {showCancel && (
        <div className="booking-wrapper">
          <Cancellation
            bookingDetail={bookingDetail}
            setBookingId={setBookingId}
            stylistUniqueCode={stylistUniqueCode}
          />
        </div>
      )}

      {!showCancel && (
        <div className="booking-wrapper">
          <div className="sign_up_screen thankyou">
            <div className="upper-wrapper">
              <div className="back-logo">
                <div className="go-back back-profile">
                  {!window.location.href.includes("appoitmentrebooked") ? (
                    <span
                      onClick={() =>
                        navigate(`/${getURLstylistCode()}/bookings`)
                      }
                    >
                      <img src={images.go_back} alt="" />
                    </span>
                  ) : (
                    <div
                      className="user-icon"
                      onClick={navOpen}
                      style={{ marginLeft: "auto" }}
                    >
                      <img src={images.user_1} alt="" />
                    </div>
                  )}

                  {showPopup && (
                    <div className="cancel-popup">
                      <div className="overlay"></div>
                      <div className="cancel-inner">
                        <h1>{STRING_CONSTANTS.confirmed_heading}</h1>
                        <p>
                          {STRING_CONSTANTS.confirmed_sure}
                          {STRING_CONSTANTS.confirmed_final}
                          <br />
                          {mainstylistLocationDataa?.stylist_info
                            ?.cancellation_charge_amount > 0 &&
                            mainstylistLocationDataa?.stylist_info
                              ?.is_cancellation_charge_active &&
                            cancelTimings && (
                              <>
                                <br />{" "}
                                <span>{STRING_CONSTANTS.please_note}</span>
                                {
                                  bookingDetail?.cancellation_charges
                                }{" "}
                                {STRING_CONSTANTS.cancel_info}{" "}
                                {
                                  bookingDetail?.consumer_payment_method_last4_digit
                                }
                                .
                              </>
                            )}
                        </p>

                        <div className="cancel-buttons">
                          <span
                            onClick={() => handleConfirm()}
                            className={disableCancel ? "disabled" : ""}
                          >
                            Yes
                          </span>
                          <span onClick={cancelpopup}>No</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="thankyou-section">
                <div className="thankyou-inner">
                  <div className="thanks-top">
                    <div className="msg-box">
                      <img src={images.confirm_icon} alt="" />
                      {bookingDetail.online_status === BOOKING_STATUS.PENDING ? (
                        <span>{STRING_CONSTANTS.appointment_requested}</span>
                      ) : (
                        <span>{STRING_CONSTANTS.appointment_confirmed}</span>
                      )}
                    </div>

                    <div className="time-outer">
                      <p className="confirm-text">
                        {STRING_CONSTANTS.confirmed_appointment}
                      </p>

                      <div className="date-time">
                        <div className="date">
                          <img src={images.date_icon} alt="" />

                          {convertToTimeZoneDate(bookingDetail?.start_dt, bookingDetail?.timezone, "Do MMM YYYY")}
                        </div>
                        <div className="time">
                          <img src={images.time_icon} alt="" />
                          {convertToTimeZoneTime(
                            bookingDetail?.start_dt,
                            bookingDetail?.timezone,
                            "hh:mm A"
                          )}- {convertToTimeZoneTime(bookingDetail?.end_dt, bookingDetail?.timezone, "hh:mm A")}
                        </div>
                      </div>
                      <div className="address-map">
                        <div className="adrs">
                          <img src={images.address_icon} alt="" />
                          {bookingDetail?.address}
                        </div>
                        <div className="map" onClick={() => showInMapClicked()}>
                          <img src={images.thanks_icon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="thanks-bottom">
                    {STRING_CONSTANTS.booking_no} {bookingDetail?.booking_id}
                  </div>
                </div>
                <div className="service-summery">
                  <span>{STRING_CONSTANTS.service_summary}</span>

                  <ul>
                    {bookingDetail?.services?.map((item) => {
                      return (
                        <li key={item.service_id}>
                          <p style={{ textAlign: "left" }}>
                            <span>
                              {item.name || item.service__name}
                              {item.duration || item.service__duration
                                ? ","
                                : ""}{" "}
                              {item.duration || item.service__duration}{" "}
                              {item.duration || item.service__duration
                                ? STRING_CONSTANTS.minutes
                                : ""}
                            </span>

                            {item.total_quantity && (
                              <>
                                <br />
                                <span className="qty">
                                  <img src={images.stock_icon} alt="" />{" "}
                                  {STRING_CONSTANTS.total_qty}
                                  {item.total_quantity}
                                </span>
                                <br />
                              </>
                            )}
                          </p>

                          <b>
                            $
                            {item.total_quantity
                              ? item.total_quantity * item.price
                              : item.price}{" "}
                          </b>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {bookingDetail?.products?.length > 0 && (
                  <div className="service-summery">
                    <span>{STRING_CONSTANTS.product_heading}</span>

                    <ul>
                      {bookingDetail?.products?.map((item) => {
                        return (
                          <li key={item.product_id}>
                            <p style={{ textAlign: "left" }}>
                              <span>{item.product || item.product__name}</span>

                              {item.stock_quantity > 0 && (
                                <>
                                  <br />
                                  <span className="qty">
                                    <img src={images.stock_icon} alt="" />{" "}
                                    {STRING_CONSTANTS.stock_qty}
                                    {item.stock_quantity}
                                  </span>
                                </>
                              )}

                              {item.warehouse_quantity > 0 && (
                                <>
                                  <br />
                                  <span className="qty warehouse">
                                    <img src={images.warehouse_icon} alt="" />{" "}
                                    {STRING_CONSTANTS.warehouse_quantity}
                                    {item.warehouse_quantity}
                                  </span>
                                </>
                              )}
                            </p>
                            <b>${item.total_quantity * item.price}</b>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {mainstylistLocationDataa?.stylist_info
                  ?.is_cancellation_charge_active && (
                  <div className="service-summery cancel-info">
                    <span>
                      {" "}
                      <img src={images.cancel_info} alt="" />
                      {STRING_CONSTANTS.cancel_info_text}
                    </span>
                    <ul>
                      <li>
                        <span>
                          {STRING_CONSTANTS.cancel_anytime}{" "}
                          {mainstylistLocationDataa?.stylist_info
                            ?.minimum_cancellation_time_frame * 24}
                          {STRING_CONSTANTS.cancel_driven}{" "}
                          <span
                            onClick={cancelpopup1}
                            className="cancel-policy"
                          >
                            {STRING_CONSTANTS.payment_cancellation}
                          </span>{" "}
                          {STRING_CONSTANTS.cancel_before}
                        </span>
                        {showPopup1 && (
                          <div className="cancel-popup">
                            <div
                              className="overlay"
                              onClick={cancelpopup1}
                            ></div>
                            <div className="cancel-inner">
                              <h2>
                                {STRING_CONSTANTS.cancellation_policy_heading}
                              </h2>
                              <p>
                                {
                                  mainstylistLocationDataa?.stylist_info
                                    ?.cancellation_policy
                                }
                              </p>
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {futureDate && (
                          <span>
                            {STRING_CONSTANTS.to_cancel}{" "}
                            <a href="javascript:void(0);" onClick={cancelpopup}>
                              {STRING_CONSTANTS.click_here}
                            </a>
                            {/* . To modify your reservation <a href="#">Click here</a>. */}
                          </span>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
                <div className="service-summery cancel-info need-assist">
                  <span>
                    <img src={images.need_assist_icon} alt="" />
                    {STRING_CONSTANTS.need_assist}
                  </span>
                  <ul>
                    <li>
                      <span>
                        {STRING_CONSTANTS.contact_us_via}{" "}
                        <a
                          href={`mailto:${mainstylistLocationDataa?.stylist_info?.email}`}
                        >
                          {mainstylistLocationDataa?.stylist_info?.email}
                        </a>
                        {STRING_CONSTANTS.or_over_phone}{" "}
                        <a
                          href={`tel:${mainstylistLocationDataa?.stylist_info?.mobile_number}`}
                        >
                          {
                            mainstylistLocationDataa?.stylist_info
                              ?.mobile_number
                          }
                        </a>
                        .
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="service-summery need-assist follow-social">
                  {mainstylistLocationDataa?.stylist_info?.instagram_handle ||
                  mainstylistLocationDataa?.stylist_info?.facebook_handle ||
                  mainstylistLocationDataa?.stylist_info?.tiktok_handle ? (
                    <span>
                      Follow {mainstylistLocationDataa?.stylist_info?.full_name}{" "}
                      socials
                    </span>
                  ) : null}

                  <ul className="social-links">
                    {mainstylistLocationDataa?.stylist_info
                      ?.instagram_handle && (
                      <li>
                        <a
                          href={
                            mainstylistLocationDataa?.stylist_info
                              ?.instagram_handle
                          }
                          target="_blank"
                        >
                          <img src={images.insta_icon} alt="" />
                        </a>
                      </li>
                    )}
                    {mainstylistLocationDataa?.stylist_info
                      ?.facebook_handle && (
                      <li>
                        <a
                          href={
                            mainstylistLocationDataa?.stylist_info
                              ?.facebook_handle
                          }
                          target="_blank"
                        >
                          <img src={images.fb_icon} alt="" />
                        </a>
                      </li>
                    )}

                    {mainstylistLocationDataa?.stylist_info?.tiktok_handle && (
                      <li>
                        <a
                          href={
                            mainstylistLocationDataa?.stylist_info
                              ?.tiktok_handle
                          }
                          target="_blank"
                        >
                          <img src={images.tiktok_icon} alt="" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="buttons">
                  {futureDate ? (
                    <>
                      <Button
                        className="continue-btn re-sch"
                        type="submit"
                        btnTxt={STRING_CONSTANTS.cancel_booking}
                        isLoading={false}
                        onClick={cancelpopup}
                      />
                      <Link
                        to={`/${getURLstylistCode()}/reschedule/?id=${
                          bookingDetail.id
                        }`}
                        className="continue-btn re-sch"
                      >
                        {STRING_CONSTANTS.booking_reschedule}
                      </Link>
                    </>
                  ) : (
                    <Link
                      to={`/${getURLstylistCode()}/rebook/?id=${
                        bookingDetail.id
                      }`}
                      className="continue-btn re-sch"
                    >
                      {STRING_CONSTANTS.booking_rebook}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
