import React, { useEffect } from "react";
import { makeGetCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import axios from "axios";



import moment from "moment";

import InputField from "../../global/ui/InputField";
import { apiConstants } from "../../utils/constants/apiConstants";
import { formatTime } from "../../utils/globalFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

const ScheduleTime = (props) => {
  const {
    getValues,
    setValue,
    control,
    errors,
    selectedIndex,
    setSelectedIndex,
    timingError,
  } = props;

  //fetching location_id from store
  const {
    selectedDate,
    setSelectedTime,
    setBookingDuration,
    selectedServices,
    timing,
    timezone_id,
  } = useStore((state: any) => state);
  const bookingDuration = useStore((state: any) => state.bookingDuration);

  if (getValues(apiConstants.location) === "mbl_booking") {
    setValue(apiConstants.timezone, timezone_id);
  }
  let fallBackTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezoneToUse = !timezone_id ? fallBackTimezone : timezone_id;
  if (!timezone_id) {
    console.log('Customer is using the fallback timezone..')
  }
  console.log(`Customer Timezone: ${timezoneToUse}`)

  let morning = timing?.filter((item) => {
    const getTimes = moment
      .tz(item, timezoneToUse)
      .format("YYYY-MM-DD HH:mm");
    
    const sydneyTime = moment
      .tz(item, 'Australia/Sydney')
      .format("HH:mm");
    console.log(`Sydney Time: ${sydneyTime}`);
    const splittime = getTimes.split(" ");

    return (
      parseInt(splittime[1].split(":")[0]) < 12
    );
  });
  
  let evening = timing?.filter((item) => {
    const getTimes = moment
      .tz(item, timezoneToUse)
      .format("YYYY-MM-DD HH:mm");
    const sydneyTime = moment
      .tz(item, 'Australia/Sydney')
      .format("HH:mm");
    console.log(`Sydney Time: ${sydneyTime}`);
    const splittime = getTimes.split(" ");

    return (
      parseInt(splittime[1].split(":")[0]) >= 12
    );
  });

  const setTiming = (timings: string) => {
    setValue(apiConstants.time, timings);
  };

  let temp = new Date(selectedDate);
  const dateString = moment(temp);

  morning = morning?.map((time) => {
    return moment
      .tz(time, timezoneToUse)
      .format("hh:mm A");
  });

  evening = evening?.map((time) => {
    return moment
      .tz(time, timezoneToUse)
      .format("hh:mm A");
  });

  //Handle Booking Duration
  const handleBookingDuration = (start) => {
    const totalDuration = selectedServices
      .map((item) => item.duration)
      .reduce((acc, curr) => acc + curr);

    setBookingDuration(start, totalDuration);
  };

  return (
    <>
      {timingError ? (
        ""
      ) : (
        <div className="timings">
          <span>
            {STRING_CONSTANTS.available_time}{" "}
            {dateString && <span>{dateString.format("Do MMMM")}</span>}
          </span>
          <div className="morning">
            <InputField
              name={apiConstants.time}
              type="text"
              className="input-hidden"
              // errors={errors}
              control={control}
            />
            {errors?.[apiConstants.time] ? (
              <p className="text-danger">
                {errors[apiConstants.time]?.message}
              </p>
            ) : null}
            <span>Morning</span>
            <ul>
              {morning?.map((morning, index) => (
                <li
                  key={morning.toString()}
                  className={`my-element ${
                    selectedIndex === index + "m" ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedIndex(index + "m");
                    handleBookingDuration(morning);
                    setSelectedTime(morning);
                    setTiming(morning);
                  }}
                >
                  {morning}
                </li>
              ))}
            </ul>
          </div>
          <div className="morning">
            <span>Afternoon /Evening</span>

            <ul>
              {evening?.map((evening, index) => (
                <li
                  key={evening.toString()}
                  className={`my-element ${
                    selectedIndex === index + "e" ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedIndex(index + "e");
                    setSelectedTime(evening);
                    setTiming(evening);
                    handleBookingDuration(evening);
                  }}
                >
                  {evening}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleTime;
