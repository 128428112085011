import React, { useEffect, useRef, useState } from "react";
import Button from "../../global/ui/Button";

import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  showToast,
} from "../../utils";
import { isEmptyOrNullOrUndefined } from "../../utils/dateUtils";
import { apiConstants } from "../../utils/constants/apiConstants";
import { makeGetCall, makePostCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import { images } from "../../utils/constants/assets";
import { getFormattedNumber } from "../../utils/globalFunctions";
import OtpInput from "../OtpInput/OtpInput";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";


interface OtpProps {
  handleNext?: any;
  setStepNo: (number) => void;
  getValues: (value: any) => void;
  handlePrevious: () => void;

  setValue?: any;
  //   getValues?: any;
}
export default function Otp(props: OtpProps) {
  const mainstylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );
  const { handleNext, getValues, handlePrevious, setValue, setStepNo } = props;

  const AuthInputRef = useRef<AuthCodeRef>(null);

  const [otp, setOtp] = useState("");
  // const [timer, setTimer] = useState(60);
  const [resendOtp, setResendOtp] = useState(false);
  const [isError, setIsError] = useState(false);
  let timerId: any = useRef(null);

  const [checkUserRegistered, setCheckUserRegistered] = useState<any>(false);

  const num = `${getValues(apiConstants.country_code)}` + `${getValues(
    apiConstants.mobile_number
  )}`.replace(/^0+/, '');

  const mobileNumber = num.toString().replace(/\s/g, "");
  const phoneNumber = parsePhoneNumber(mobileNumber)

  async function checkUserApi() {
    const phone_check = {
      mobile_number: phoneNumber.number,
    };

    try {
      const apiResponse = await makeGetCall({
        url: endpoints.otp,
        params: phone_check,
      });

      const data = await apiResponse;

      if (data.status.code === 200) {
        setCheckUserRegistered(data?.data);
      } else {
        showToast(data.status.message, "error");
      }
    } catch (err) {}
  }

  async function verifyOtpApi() {
    let apiPayload = {
      token: checkUserRegistered?.token,
      otp: otp,
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.otp,
        apiPayload,
      });

      if (apiResponse.status.code === 200) {
        if (apiResponse?.data?.is_registered_online_consumer) {
          setStepNo(7);

          if (apiResponse?.token.access) {
            setLocalStorage("access_token", apiResponse?.token?.access);
            removeLocalStorage("is_registered_online_consumer");

            const is_not_registered = getLocalStorage(
              "is_registered_online_consumer1"
            );
            if (window.location.href.includes("signin")) {
              // if (is_not_registered) {
              //   setStepNo(7);
              // }
              handleNext();
            }
          }
        } else {
          handleNext();
          // if (window.location.href.includes("signin")) {
          //   handleNext();
          // }
        }
      } else {
        showToast(apiResponse.status.message, "error");
      }
    } catch (err) {}
  }

  useEffect(() => {
    checkUserApi();
  }, []);

  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(60);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (secs <= 0) {
        if (mins <= 0) return;
        else {
          setMins((m) => m - 1);
          setSecs(59);
        }
      } else setSecs((s) => s - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [secs, mins]);

  // const handleStart = () => {

  //   timerId.current = setInterval(() => {
  //     if (timer >= 0) {
  //       setTimer((timer) => timer - 1);
  //     } else {
  //       clearInterval(timerId.current);
  //     }
  //   }, 1000);
  // };

  const showReset = () => {
    setResendOtp(true);
  };

  useEffect(() => {
    if (secs === 0) {
      showReset();
    }
  }, [secs]);

  const handleChange = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const handleOtp = () => {
    // add otp api here.
    if (isEmptyOrNullOrUndefined(otp)) {
      setIsError(true);
      showToast(STRING_CONSTANTS.enter_the_otp, "error");
    } else if (otp.length !== 6) {
      setIsError(true);
      showToast(STRING_CONSTANTS.enter_valid_otp, "error");
    }
    // else if (secs < 0) {
    //   setIsError(true);
    //   showToast(STRING_CONSTANTS.Verification_code_expire, "error");
    // }
    else {
      const params = {
        otp: otp,
        number: getValues("mobile_number"),
      };
      if (otp.length < 6) {
        return;
      } else {
        verifyOtpApi();

        // setIsError(true);
        // showToast(STRING_CONSTANTS.enter_valid_otp, "error");
      }
    }
  };

  const handleResetOtp = () => {
    checkUserApi();

    setSecs(60);
    setResendOtp(false);
    AuthInputRef.current?.clear();
  };

  const is_registered = getLocalStorage("is_registered_online_consumer");
  const countryCode = getValues(apiConstants.country_code);
  const cleanMobileNum = String(getValues(apiConstants.mobile_number)).replace(/\s/g, "");
  const internationalPhoneNumber = parsePhoneNumber(`${countryCode}${cleanMobileNum}`).number;
  
  return (
    <div className="step_5 sign_up_screen otp_screen">
      <div className="upper-wrapper">
        <div className="back-logo">
          <div className="go-back" onClick={handlePrevious}>
            <img src={images.go_back} alt="" />
          </div>
        </div>

        {is_registered ? <h1>Welcome back!</h1> : <h1>Get started today.</h1>}

        {is_registered ? (
          <p>
            {STRING_CONSTANTS.otp_sign}{" "}
            {mainstylistLocationData?.stylist_info?.full_name}.
          </p>
        ) : (
          <p>
            {STRING_CONSTANTS.enter_otp}{" "}
            {mainstylistLocationData?.stylist_info?.full_name}.{" "}
          </p>
        )}

        {is_registered ? (
          <p className="verification_number">
            {`Verify your number, we’ve sent a one time login code to your mobile
          
            number: ${internationalPhoneNumber}`}
          </p>
        ) : (
          <p className="verification_number">
            {`Verify your number, we’ve sent a one time sign-up code to your mobile
            number: ${internationalPhoneNumber}`}
          </p>
        )}

        <div className="otp_number">
          {/* <input type="number" /> */}

          <OtpInput value={otp} valueLength={6} onChange={handleChange} />

          <p className="resend_msg">
            Didn’t receive a code?{" "}
            {resendOtp ? (
              <span style={{ cursor: "pointer" }} onClick={handleResetOtp}>
                Send again{" "}
              </span>
            ) : (
              <b>Resend in {secs} secs </b>
            )}
          </p>
        </div>
      </div>

      <div className="bottom-wrapper">
        <Button
          className="continue-btn"
          type="submit"
          btnTxt={"Continue"}
          isLoading={false}
          onClick={handleOtp}
        />
      </div>
    </div>
  );
}
