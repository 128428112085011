import React, { useEffect, useRef, useState } from "react";
import Button from "../../global/ui/Button";
import Stripe from "./Stripe";
import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../zustandstore";
import moment from "moment";
import { makeGetCall, makePostCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import axios from "axios";
import { apiConstants } from "../../utils/constants/apiConstants";
import PaymentApplePay from "./PaymentApplePay";
import {
  convertDateToUTC,
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { showToast } from "../../utils";


const stripeKey: any = process.env.REACT_APP_SECRET_KEY;
const stripePromise = loadStripe(stripeKey);


interface PaymentProps {
  handleNext: () => void;
  control?: any;
  errors?: any;
  watch?: any;
  setValue?: any;
  handlePrevious?: () => void;
  getValues?: any;
  totalAmountArr?: any;
}

interface ServiceData {
  id: string;
  price: number;
  sequence_number: number;
  application_time?: number | null;
  processing_time?: number | null;
  finishing_time?: number | null;
}

export default function Payment(props: PaymentProps) {
  const {
    control,
    errors,
    handleNext,
    watch,
    handlePrevious,
    setValue,
    getValues,
  } = props;
  let { totalAmountArr } = props;
  const serviceIdArray: any = [];
  const [note, setNote] = useState("");
  const [isDeposit, setDeposit] = useState<boolean | undefined>(undefined);
  const [isCancellation, setIsCancellation] = useState<boolean | undefined>(undefined);

  const [isReBook, setReBook] = useState(false);
  const [rebookID, setReBookID] = useState<any>(null);
  const {
    location_id,
    stylistLocationData,
    selectedDate,
    selectedTime,
    selectedServices,
    bookingDuration,
    setStylistLocationData,
    setSelectedService,
    stylistUniqueCode,
    rebookLocation,
    setBookingAPIData,
  } = useStore((state: any) => state);
  const navigate = useNavigate();
  const [bookingAddress, setBookingAddress] = useState("");
  useEffect(() => {
    if (window.location.href.includes("rebookpayment")) {
      setReBook(!isReBook);
      // window.location.href = "/";
    }
  }, []);

  // let isCancellation, isDeposit;
  async function getStylistData() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      setStylistLocationData(data);

      // Check if user is in stylist contacts and if payment is only required for new users
      const isInStylistContacts = data?.is_in_stylist_contacts;
      const enablePaymentJustForNewUsers = data?.stylist_info?.enable_payment_just_for_new_users;

      // If user is in contacts and payment is only for new users, skip deposit and cancellation
      if (isInStylistContacts && enablePaymentJustForNewUsers) {
        setDeposit(false);
        setIsCancellation(false);
      } else {
        setDeposit(data?.stylist_info?.is_online_deposit_allowed);
        setIsCancellation(data?.stylist_info?.is_cancellation_charge_active);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getStylistData();
  }, []);

  let totalAmount = totalAmountArr?.reduce(
    (amount, currentTotal) => amount + Number(currentTotal?.amount),
    0.0
  );

  if (isReBook === true) {
    totalAmount = selectedServices.reduce(
      (amount, curr) => amount + +curr.price,
      0
    );
  }

  //Get Address
  let locationData: any; //Use it pass to Stripe

  const getaddress = () => {
    if (window.location.href.includes("rebookpayment")) {
      if (rebookLocation) {
        setBookingAddress(rebookLocation?.address);
      } else {
        const urlParams = new URLSearchParams(window.location.search);

        makeGetCall({
          url: `${endpoints.booking}${urlParams.get("id")?.split("/")[0]
            }/?${getURLwithstylistCode()}`,
        }).then((res) => {
          setBookingAddress(res?.data?.address);
        });
      }
    } else {
      if (getValues(apiConstants.location) === "mbl_booking") {
        locationData = getValues(apiConstants.custom_location_object);

        return getValues(apiConstants.custom_location);
      } else {
        let address = "";
        stylistLocationData?.results?.forEach((item) => {
          if (item?.id === location_id) {
            console.log('Payment Loc ID:', location_id)
            locationData = item;
            // address = `${item.name} ${item.address}`;
            address = ` ${item.address}`;
          }
        });

        return address;
      }
    }
  };

  useEffect(() => {
    getaddress();
  }, []);

  //Approx total Tooltip
  const [approxTotalPopUp, setApproxTotalPopUp] = useState(false);

  const approxTotal = () => {
    setApproxTotalPopUp(!approxTotalPopUp);
  };
  //Render Tooltip function
  const renderTooltip = selectedServices.map((item) => {
    return (
      <div className="approx-inner">
        <div className="name">{item.name}</div>
        <div className="amount"> $ {item.amount}</div>
      </div>
    );
  });

  //++formatting Date++
  let temp = new Date(selectedDate);
  const dateString = moment(temp);

  //+++Service Summary++++
  const renderSelectedService = selectedServices.map((item) => {
    const serviceData: ServiceData = {
      id: item.serviceid ?? item.service_id,
      price: item.amount ?? item.price,
      sequence_number: item.sequence_number,
    };
    if (item.has_processing_time) {
      serviceData.processing_time = item.processing_time;
      serviceData.finishing_time = item.finishing_time;
      serviceData.application_time = item.application_time;
    }
    serviceIdArray.push(serviceData)
    return (
      <li key={item.serviceid}>
        <span>
          {item.name}, {item.duration} minutes
        </span>
        <b>${isReBook ? item?.price : item?.amount}</b>
      </li>
    );
  });

  //+++++Calculate Date and Time
  // const time =
  //   selectedTime &&
  //   `${selectedTime.trim().slice(0, 7).replace(/\s/g, "")} ${selectedTime.slice(
  //     8
  //   )}`;

  // console.log(selectedTime, "selectedTime", time);

  // const date = new Date(`01/01/2022 ${time}`);
  // const startTime = date.toLocaleTimeString("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  // });

  // const start_dt = `${selectedDate} ${startTime}`;

  const start_dt = moment(
    `${selectedDate} ${selectedTime}`,
    "YYYY-MM-DD hh:mm A"
  ).format("YYYY-MM-DD HH:mm");
  //++++calculate end time
  // const [hours, minutes] = startTime.split(":"); // split the input time into hours and minutes
  // const tempDate = new Date();
  // tempDate.setHours(parseInt(hours)); // set the hours of the date object to the hours from the input time
  // tempDate.setMinutes(parseInt(minutes) + bookingDuration.duration); // add the duration to the minutes of the date object
  // const endTime = tempDate.toLocaleTimeString("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  // });

  const end_dt = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD hh:mm A")
    .add(bookingDuration.duration, "minutes")
    .format("YYYY-MM-DD HH:mm");

  // const end_dt = `${selectedDate} ${endTime}`;

  //Calculate Deposit

  let depositAmount, remainingAmount: any;

  if (stylistLocationData?.stylist_info?.online_deposit_type === 0) {
    depositAmount = Number(
      stylistLocationData?.stylist_info?.online_deposit_amount
    );

    if (totalAmount <= depositAmount) {
      depositAmount = totalAmount;
    }

    remainingAmount = Number(totalAmount - depositAmount).toFixed(2);
  } else {
    depositAmount =
        Number((totalAmount * stylistLocationData?.stylist_info?.online_deposit_amount) / 100).toFixed(2);
  }

  remainingAmount = Number(totalAmount - depositAmount).toFixed(2);
  //Calculate Cancellation
  let cancelCharge;
  if (stylistLocationData?.stylist_info?.cancellation_charge_type === 0) {
    cancelCharge =
      stylistLocationData?.stylist_info?.cancellation_charge_amount;
  } else {
    cancelCharge =
      (totalAmount *
        stylistLocationData?.stylist_info?.cancellation_charge_amount) /
      100;
  }

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    let rebooktemp: any;
    let bookingAPIPayload: any = {
      services: JSON.stringify(serviceIdArray),
      start_dt,
      end_dt,
      [apiConstants.address]: locationData?.[apiConstants.address],
      [apiConstants.country]: locationData?.[apiConstants.country],
      [apiConstants.country_code]: locationData?.[apiConstants.country_code],
      [apiConstants.location]: locationData?.[apiConstants.location],
      [apiConstants.city]: locationData?.[apiConstants.city],
      [apiConstants.zip_code]: locationData?.[apiConstants.zip_code],
      [apiConstants.state]: locationData?.[apiConstants.state],
      [apiConstants.state_code]: locationData?.[apiConstants.state_code],
      [apiConstants.location_image]:
        locationData?.[apiConstants.location_image],
    };

    if (!isReBook) {
      if (getValues(apiConstants.location) !== "mobile_booking") {
        bookingAPIPayload["location_selected"] = locationData?.id;

        // bookingAPIPayload["timezone"] = locationData?.timezone;
      }
    }

    if (isReBook) {
      bookingAPIPayload = {
        ...bookingAPIPayload,
        [apiConstants.address]: rebookLocation?.address,
        [apiConstants.city]: rebookLocation?.[apiConstants.city],
        [apiConstants.state]: rebookLocation?.[apiConstants.state],
        [apiConstants.state_code]: rebookLocation?.[apiConstants.state_code],
        [apiConstants.zip_code]: rebookLocation?.[apiConstants.zip_code],
        [apiConstants.country]: rebookLocation?.[apiConstants.country],
        [apiConstants.country_code]:
          rebookLocation?.[apiConstants.country_code],
        [apiConstants.location]: rebookLocation?.[apiConstants.location],
        [apiConstants.location_image]:
          rebookLocation?.[apiConstants.location_image],
        // [apiConstants.timezone]: rebookLocation?.[apiConstants.timezone],
      };

      if (rebookLocation?.location_selected != null) {
        bookingAPIPayload["location_selected"] =
          rebookLocation?.location_selected;
      }
    }
    if (note) {
      bookingAPIPayload = { ...bookingAPIPayload, note };
    }

    try {
      makePostCall({
        url: `${endpoints.API_BASE_URL}booking/?${getURLwithstylistCode()}`,
        apiPayload: bookingAPIPayload,
        xTimezone: tz,
      })
        .then(function (result) {
          if (result.error) {
            // console.log("Unsucessfull", result?.error.message);
            setIsSubmitting(false);
          } else if (result?.status?.code === 200) {
            if (isReBook) {
              rebooktemp = result?.data?.id;
              navigate(
                `/${getURLstylistCode()}/booking_details/?appoitmentrebooked&id=${rebooktemp}`
              );
            }
            setBookingAPIData(result?.data);
            handleNext();
          } else {
            showToast(result?.status?.message, "error");
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const mainstylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );

  const getaddress1 = () => {
    const selectedData = mainstylistLocationData?.results?.find((item) => {
      return item.id === location_id;
    });

    return selectedData;
  };

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
      getaddress1()?.location[0] +
      "," +
      getaddress1()?.location[1],
      "_blank"
    );
  };

  const [showPopup, setShowPopup] = useState(false);

  const [depositPopup, setDepositPopup] = useState(false);

  const cancelpopup = () => {
    setShowPopup(!showPopup);
  };

  const depositPop = () => {
    setDepositPopup(!depositPopup);
  };

  return (
    <div className="booking-wrapper">
      <div className="step_6 sign_up_screen one">
        <div className="upper-wrapper">
          <div className="back-logo">
            {window.location.href.includes("rebookpayment") ? (
              <div
                className="go-back"
                onClick={() => {
                  navigate(-1);
                  // setStepNo(2);
                }}
              >
                <img src={images.go_back} alt="" />
              </div>
            ) : (
              <div className="go-back" onClick={handlePrevious}>
                <img src={images.go_back} alt="" />
              </div>
            )}
          </div>
          <h1>{STRING_CONSTANTS.we_are_done}</h1>

          <p className="cancellation">
            {STRING_CONSTANTS.review_your_appointment}
            <br />
            <br />
            {stylistLocationData?.stylist_info
              ?.is_cancellation_charge_active && (
                <>
                  {STRING_CONSTANTS.by_continue}
                  <span onClick={cancelpopup} className="cancel-policy">
                    {STRING_CONSTANTS.payment_cancellation}
                  </span>
                </>
              )}
          </p>

          {showPopup && (
            <div className="cancel-popup">
              <div className="overlay" onClick={cancelpopup}></div>
              <div className="cancel-inner">
                <h2>{STRING_CONSTANTS.payment_cancellation}</h2>
                <p> {stylistLocationData?.stylist_info?.cancellation_policy}</p>
                {/* <div className="cancel-buttons"> */}
                {/* <span onClick={cancelpopup}>Close</span> */}
                {/* </div> */}
              </div>
            </div>
          )}

          {depositPopup && (
            <div className="cancel-popup">
              <div className="overlay" onClick={depositPop}></div>
              <div className="cancel-inner">
                <h2>{STRING_CONSTANTS.deposit_heading}</h2>
                <p> {STRING_CONSTANTS.this_booking_requires}</p>
                {/* <div className="cancel-buttons"> */}
                {/* <span onClick={cancelpopup}>Close</span> */}
                {/* </div> */}
              </div>
            </div>
          )}
          <div className="service-summery appointment-summery">
            <span>{STRING_CONSTANTS.appoint_summery}</span>
            <ul>
              <li>
                <span>{STRING_CONSTANTS.location_text}</span>

                {location_id === "mbl_booking" ? (
                  <p>{getaddress()}</p>
                ) : (
                  <p className="loc-click" onClick={() => showInMapClicked()}>
                    {bookingAddress || getaddress()}
                  </p>
                )}
              </li>
              <li>
                <span>{STRING_CONSTANTS.date_time}</span>
                <p>
                  {dateString.format("Do MMMM YYYY")}, {selectedTime}
                </p>
              </li>
            </ul>
          </div>
          <div className="service-summery">
            <span>{STRING_CONSTANTS.service_summary}</span>
            <ul>{renderSelectedService}</ul>
          </div>

          <div className="service-summery payment-method">
            {stylistLocationData?.stylist_info?.is_online_deposit_allowed && !isDeposit && (
              <span>
                {" "}
                <img
                  src="/assets/images/question.svg"
                  alt=""
                  onClick={depositPop}
                  style={{
                    marginRight: " 10px",
                    position: "relative",
                    top: "5px",
                    width: "20px",
                  }}
                />
                {STRING_CONSTANTS.deposit_heading}
              </span>
            )}

            {(isCancellation || isDeposit) ? (
              <>
                <span>{STRING_CONSTANTS.Payment_Method}</span>
                <Elements stripe={stripePromise}>
                  <Stripe
                    serviceIdArray={serviceIdArray}
                    locationData={locationData}
                    control={control}
                    getValues={getValues}
                    handleNext={handleNext}
                    totalAmount={totalAmount}
                    remainingAmount={remainingAmount}
                    depositAmount={depositAmount}
                    cancelCharge={cancelCharge}
                    start_dt={start_dt}
                    end_dt={end_dt}
                    isCancellation={isCancellation}
                    isDeposit={isDeposit}
                    stylistLocationData={stylistLocationData}
                  />
                </Elements>
              </>
            ) : (
              <div>
                <label>
                  {STRING_CONSTANTS.additional_info}
                  <textarea
                    value={note}
                    placeholder="Tell us more"
                    onChange={(e) => setNote(e.target.value)}
                    required
                  />
                </label>
                <div className="bottom-wrapper">
                  <div className="all-totals">
                    <div className="approx-total">
                      {approxTotalPopUp && (
                        <div className="approx">
                          <div style={{ width: "100%" }}>{renderTooltip}</div>
                        </div>
                      )}

                      <div>
                        <img
                          src="/assets/images/question.svg"
                          alt=""
                          onClick={approxTotal}
                        />
                        {STRING_CONSTANTS.approx_total}
                      </div>
                      <div>${Number(totalAmount).toFixed(2)}</div>
                    </div>
                  </div>
                </div>
                <button
                  className="continue-btn"
                  onClick={() => handleConfirm()}
                  disabled={isSubmitting}
                >
                  {STRING_CONSTANTS.confirm_text}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
