import { yupResolver } from "@hookform/resolvers/yup";
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  PhoneNumber,
} from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../effects/requests";
import Button from "../../global/ui/Button";
import InputField from "../../global/ui/InputField";
import { getLocalStorage, showToast } from "../../utils";
import { apiConstants } from "../../utils/constants/apiConstants";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import endpoints from "../../utils/endpoints";
import { compareJSON } from "../../utils/globalFunctions";
import ReVerifyOtp from "./ReVerifyOtp";
import InputMask from "react-input-mask";
import { myProfileValidation, signupValidation } from "./ValidationSchema";
import useStore from "../../zustandstore";
import { images } from "../../utils/constants/assets";

// const apiResponse = {
//   id: "abc",
//   first_name: "rishab",
//   last_name: "xyz",
//   mobile_number: "123",
//   email: "abc@saasas.com",
// };

export default function MyProfile() {
  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      // address: "",
    },

    resolver: yupResolver(myProfileValidation),

    mode: "onChange",
  });

  const [bookingDetail, setBookingDetail] = useState<any>();

  const getResponse = async () => {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.profile}`,
      });

      setBookingDetail(apiResponse);

      getProfileDetail(apiResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getResponse();
  }, []);

  const getProfileDetail = (apiResponse) => {
    const formObject = apiResponse;
    Object.keys(formObject?.data).map((i: any) => {
      if (
        i === apiConstants.first_name ||
        i === apiConstants.last_name ||
        i === apiConstants.mobile_number ||
        i === apiConstants.email
        // i === apiConstants.address
      ) {
        setValue(i, formObject?.data[i]);
      }
    });
  };

  /* phone check api start */
  async function Mobilecheckapi() {
    const phone_check = {
      mobile_number: watch("mobile_number").slice(0).replace(/\s/g, ""),
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.check_number,
        apiPayload: phone_check,
      });

      const data = await apiResponse;

      if (data.status.code === 200) {
        // handleNext();
        // Mobilecheckapi();
        // setShowPopup(true);
        const getMobileNumber = bookingDetail?.data?.mobile_number;
        const watchMobileNumber = watch("mobile_number").replace(/\s/g, "");
        if (getMobileNumber != watchMobileNumber) {
          setShowPopup(true);
        }
      } else {
        showToast(data.status.message, "error", "check_number_toast");
        setShowPopup(false);
      }
    } catch (err) {}
  }

  const clickoverlay = () => {
    setShowPopup(false);
  };
  /* phone check api ends */

  /* handle save changes start */
  const handleNext = () => {
    const getMobileNumber = bookingDetail?.data?.mobile_number;
    const watchMobileNumber = watch("mobile_number").replace(/\s/g, "");

    if (getMobileNumber != watchMobileNumber) {
      // Mobilecheckapi();

      let phoneNumber: PhoneNumber | string = "";
      try {
        phoneNumber = parsePhoneNumber(watch("mobile_number"));
      } catch (error) {
        showToast(STRING_CONSTANTS.country_invalid, "error");
        return;
      }

      const isPhonePossible =
        isPossiblePhoneNumber(watch("mobile_number")) === true;

      const isValidPhone = isValidPhoneNumber(watch("mobile_number")) === true;

      if (phoneNumber && isValidPhone && isPhonePossible) {
        Mobilecheckapi();
      } else {
        showToast(STRING_CONSTANTS.invalid_number_entered, "error");
      }

      return;
    } else {
      handleSubmit(async (data1) => {
        const phoneNumber = parsePhoneNumber(getValues("mobile_number"));
        const userData = {
          first_name: getValues("first_name"),
          last_name: getValues("last_name"),
          mobile_number: phoneNumber.number,
          email: getValues("email"),
        };

        const apiResponse = await makePatchCall({
          url: `${endpoints.profile}`,
          apiPayload: userData,
        });

        const data = apiResponse;
        if (apiResponse.status.code === 200) {
          showToast(data?.status.message, "success", "one");
        } else {
          showToast(apiResponse.status.detail.email[0], "error");
        }
      })();
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileMask, setMobileMask] = useState("");

  useEffect(() => {
    if (bookingDetail) {
      if (bookingDetail?.data?.mobile_number.length === 9) {
        setMobileMask("99 999 999");
      } else if (bookingDetail?.data?.mobile_number.length === 10) {
        setMobileMask("99 999 999");
      } else if (mobileNumber.length === 11) {
        setMobileMask("9999 999 9999");
      } else {
        setMobileMask("99 999 999 999 999");
      }
    }
  }, [mobileNumber, bookingDetail]);

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div className="booking-wrapper">
      {/* otp popup */}

      {showPopup && (
        <div className="popup-outer">
          <div className="overlay" onClick={clickoverlay}></div>
          <div className="popup-inner">
            <ReVerifyOtp
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              watch={watch}
              handleSubmit={handleSubmit}
              getValues={getValues}
              getResponse={getResponse}
              getProfileDetail={getProfileDetail}
              setBookingDetail={setBookingDetail}
            />

            {/* <button onClick={togglePopup}>Hide Popup</button> */}
          </div>
        </div>
      )}
      <div className="step_5 sign_up_screen">
        <div className="upper-wrapper">
          <div className="back-logo">
            <div className="go-back" onClick={navOpen}>
              <img src={images.go_back} alt="" />
            </div>
          </div>

          <h1>My Profile & Settings</h1>
          <p>View & update your added details</p>
          <br />

          <div className="Name form-field">
            <label htmlFor="firstname">First Name</label>
            <InputField
              name={apiConstants.first_name}
              // value="fknmfm"
              type="text"
              control={control}
              errors={errors}
            />
          </div>
          <div className="Name form-field">
            <label htmlFor="lastname">Last Name</label>

            <InputField
              name={apiConstants.last_name}
              // value="fjuhfu"
              type="text"
              control={control}
              id="lastname"
            />
          </div>

          <div className="email form-field">
            <label htmlFor="emailid">Email Address</label>
            <InputField
              name={apiConstants.email}
              type="email"
              control={control}
              id="emailid"
              errors={errors}
            />
          </div>

          <div className="email form-field">
            <label htmlFor="mobile">Mobile Number</label>
            {/* <InputField
              name={apiConstants.mobile_number}
              type="text"
              control={control}
              id="mobile"
              errors={errors}
            /> */}

            <InputMask
              type="tel"
              mask={"+" + mobileMask}
              maskChar={null}
              value={watch("mobile_number")}
              onChange={(e: any) => {
                setValue("mobile_number", e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
          </div>
        </div>
        <div className="bottom-wrapper">
          <Button
            className="continue-btn"
            type="submit"
            btnTxt={"Save Changes"}
            isLoading={false}
            disabled={!isValid}
            onClick={() => handleNext()}
          />
        </div>
      </div>
    </div>
  );
}
